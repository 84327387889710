.dropdown-menu{
    width: 200px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    background : #A1AC9E;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background : #690A62;
    cursor: pointer;
    border: 1px solid #A1AC9E;
}

.dropdown-menu.clicked{
    display: none;

}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}